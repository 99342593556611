import {type Mark, type Editor} from '@englex/slate';

import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import NestedToolbar from '../NestedToolbar';
import type FontSize from './FontSize';
import FontSizeDefault from './FontSizeDefault';
import FontSizeSmall from './FontSizeSmall';
import FontSizeSmallest from './FontSizeSmallest';
import FontSizeLarge from './FontSizeLarge';
import FontSizeLargest from './FontSizeLargest';
import {getActiveMarks} from '../../queries';
import './FontSize.scss';
import FontSizeSmaller from './FontSizeSmaller';

class FontSizeToolbar extends NestedToolbar {
  public plugins: FontSize[] = [
    new FontSizeLargest({toolbar: this}),
    new FontSizeLarge({toolbar: this}),
    new FontSizeDefault({toolbar: this}),
    new FontSizeSmall({toolbar: this}),
    new FontSizeSmaller({toolbar: this}),
    new FontSizeSmallest({toolbar: this})
  ];

  public icon = 'text-height';
  public title = buttonTitle.FontSize;
  public type = ButtonType.FONT_SIZE;
  public vertical = true;

  public isActive = (editor: Editor) =>
    getActiveMarks(editor).some((m: Mark) => m.type === this.type);
}

export default FontSizeToolbar;
