import FontSize from './FontSize';
import {ButtonType} from '../../interface';
import {buttonLabel, buttonTitle} from '../i18n';
import RenderFontSizeSmall from '../../../../plugins/renderers/FontSize/FontSizeSmall';

class FontSizeSmall extends FontSize {
  public type = ButtonType.FONT_SIZE_SMALL;
  public title = buttonTitle.FontSizeSmall;
  public label = buttonLabel.FontSizeSmall;
  public className = 'small';

  public plugins = [new RenderFontSizeSmall()];
}

export default FontSizeSmall;
