import {type Plugin} from '@englex/slate-react';

import ListItem from './ListItem';
import OrderedList from './OrderedList';
import UnorderedList from './UnorderedList';
import {Indent} from './Indentation/Indent';
import {Outdent} from './Indentation/Outdent';

export class NestedLists implements Plugin {
  public plugins: Plugin[] = [
    new OrderedList(),
    new UnorderedList(),
    new Indent(),
    new Outdent(),
    new ListItem()
  ];
}
