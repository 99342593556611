import FontSize from './FontSize';
import {ButtonType} from '../../interface';
import {buttonLabel, buttonTitle} from '../i18n';
import RenderFontSizeLargest from '../../../../plugins/renderers/FontSize/FontSizeLargest';

class FontSizeLargest extends FontSize {
  public type = ButtonType.FONT_SIZE_LARGEST;
  public title = buttonTitle.FontSizeLargest;
  public label = buttonLabel.FontSizeLargest;
  public className = 'largest';

  public plugins = [new RenderFontSizeLargest()];
}

export default FontSizeLargest;
