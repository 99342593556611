import ClassNameMark from '../ClassNameMark';
import {SlateMark} from '../../../../interface';

abstract class FontSize extends ClassNameMark {
  public mark = SlateMark.FONT_SIZE;
  public icon = undefined;

  public getClassNames = (): string => {
    // todo: fontsize renderer plugin's method getMarkClassNames() should be used
    return this.className ? `${this.mark}-${this.className}` : this.mark;
  };
}

export default FontSize;
