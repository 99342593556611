import FontSize from './FontSize';
import {ButtonType} from '../../interface';
import {buttonLabel, buttonTitle} from '../i18n';
import RenderFontSizeSmaller from '../../../../plugins/renderers/FontSize/FontSizeSmaller';

class FontSizeSmaller extends FontSize {
  public type = ButtonType.FONT_SIZE_SMALLER;
  public title = buttonTitle.FontSizeSmaller;
  public label = buttonLabel.FontSizeSmaller;
  public className = 'smaller';

  public plugins = [new RenderFontSizeSmaller()];
}

export default FontSizeSmaller;
