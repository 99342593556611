import {type Editor, type Mark} from '@englex/slate';

import FontSize from './FontSize';
import {buttonLabel, buttonTitle} from '../i18n';
import {ButtonType} from '../../interface';
import RenderFontSizeDefault from '../../../../plugins/renderers/FontSize/FontSizeDefault';

class FontSizeDefault extends FontSize {
  public type = ButtonType.FONT_SIZE_DEFAULT;
  public title = buttonTitle.FontSizeDefault;
  public label = buttonLabel.FontSizeDefault;
  public className = undefined;

  public plugins = [new RenderFontSizeDefault()];

  public isActive = (editor: Editor): boolean =>
    !editor.value.marks.some((mark: Mark) => mark.type === this.mark);
}

export default FontSizeDefault;
