import {type Editor} from '@englex/slate';

import ToolbarButton from '../../../ToolbarButton';
import {getSelectedBlocksCommonList} from '../changes';

export abstract class Indentation extends ToolbarButton {
  public static maxLevel: number = 10;

  public isDisabled(editor: Editor) {
    if (super.isDisabled(editor)) {
      return true;
    }

    const {value} = editor;
    const {selection} = value;
    // behavior, common to both indent and outdent buttons: if selection is not focused or multiple list selected, disable button
    return !!(selection?.isExpanded && !getSelectedBlocksCommonList(value));
  }
}
