import TextAlign from './TextAlign';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import {JustifyContentValue, TextAlignValue} from '../../../../interface';

class TextAlignJustify extends TextAlign {
  public align = TextAlignValue.JUSTIFY;
  public justify = JustifyContentValue.JUSTIFY;
  public type = ButtonType.TEXT_ALIGN_JUSTIFY;
  public title = buttonTitle.TextAlignJustify;
  public icon = 'align-justify';
  public shortcut = 'mod+shift+j';
}

export default TextAlignJustify;
