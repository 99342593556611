import {type Block, Data, type Editor} from '@englex/slate';

import {
  getAncestorListOfDepth,
  getClosestListAncestor,
  getListIndentLevel,
  getPrevSiblingsNumber
} from './utils';
import {getListItemBlockOfBlock} from '../utils';
import {
  type ListItemBlock,
  type ListStyleType,
  type ListType,
  SlateBlock
} from '../../../../../interface';
import {isListBlock} from '../../../../../utils';

import {Indentation} from './index';

function moveToPrevLiAndCreateList(
  ch: Editor,
  targetLi: Block,
  prevLi: Block,
  list: ListType,
  listStyleType?: ListStyleType
) {
  const data = Data.create({}).withMutations(d => {
    d.set('list', list);
    if (listStyleType) {
      d.set('listStyleType', listStyleType);
    }
  });
  ch.moveNodeByKey(targetLi.key, prevLi.key, prevLi.nodes.size).wrapBlockByKey(targetLi.key, {
    type: SlateBlock.LIST,
    data
  });
}

function moveEmbeddedListToPrevLiAndMerge(ch: Editor, embeddedList: Block, prevLi: Block) {
  ch.moveNodeByKey(embeddedList.key, prevLi.key, prevLi.nodes.size).mergeNodeByKey(
    embeddedList.key
  );
}

export const indent = (ch: Editor) => {
  const {blocks} = ch.value;

  blocks.forEach((block: Block) => {
    const {document: doc} = ch.value;
    // the list, from which the item would be moved
    const targetList = getClosestListAncestor(doc, block);
    // list item, which would be moved
    const targetLi = getListItemBlockOfBlock(doc, block);

    if (!targetList || !targetLi) {
      return;
    }

    const destinationDepthLevel = getListIndentLevel(doc, targetList) + 1;
    if (destinationDepthLevel > Indentation.maxLevel) {
      return;
    }

    const listType = targetList.data.get('list');
    const listStyleType = targetList.data.get('listStyleType');

    // assert as block here because indent button is disabled for first item in list
    const previousBlock = doc.getPreviousBlock(block.key) as Block;
    const previousLi = doc.getPreviousSibling(targetLi.key) as ListItemBlock;

    const listInPrevLi = getAncestorListOfDepth(doc, previousBlock, destinationDepthLevel);

    ch.withoutNormalizing(() => {
      const change = ch;
      // move target li to previous li and wrap it by list of the same type.
      // disable normalization, so it won't normalize two lists in one list item.
      change.command(moveToPrevLiAndCreateList, targetLi, previousLi, listType, listStyleType);

      if (listInPrevLi) {
        const {document: newDoc} = change.value;
        // if there was already list in previous li, merge it with newly created one;
        // we created this list in moveToPrevLiAndCreateList change, so we are sure it does exist
        const createdList = getClosestListAncestor(newDoc, block) as Block;
        ch.mergeNodeByKey(createdList.key);
      }

      // use old version of targetLi because it wasn't normalized or removed
      const embeddedList = targetLi && targetLi.nodes.find(node => !!node && isListBlock(node));
      if (embeddedList) {
        // if there is embedded list inside current target li, move it to previous li and merge with
        // list created by moveToPrevLiAndCreateList
        const {document: newDoc} = change.value;
        // get new version of this node with updated nodes number, we are sure it does exist because no normalization
        // here and we didn't delete previous li
        const newPrevLi = newDoc.getNode(previousLi.key) as Block;
        change.command(moveEmbeddedListToPrevLiAndMerge, embeddedList, newPrevLi);
      }
    });
  });
};

function moveTargetLiToDestinationList(ch: Editor, destinationList: Block, targetLi: Block) {
  const destinationListPrevItem = destinationList.nodes.find((node: Block) =>
    node.hasDescendant(targetLi.key)
  ) as Block;
  const destinationIndex = destinationList.nodes.indexOf(destinationListPrevItem) + 1;
  ch.moveNodeByKey(targetLi.key, destinationList.key, destinationIndex);
}

function makeEmbeddedListFromNextListItems(
  ch: Editor,
  targetLi: Block,
  listToBecomeEmbedded: Block
) {
  ch.moveNodeByKey(listToBecomeEmbedded.key, targetLi.key, targetLi.nodes.size);
}

export const outdent = (ch: Editor) => {
  const {blocks} = ch.value;

  blocks.reverse().forEach((block: Block) => {
    const {document: doc} = ch.value;

    // the list, from which the item would be moved
    const targetList = getClosestListAncestor(doc, block);
    // list item, which would be moved
    const targetLi = getListItemBlockOfBlock(doc, block);

    if (!targetList || !targetLi) {
      return;
    }

    // number of items in closestListAncestor before blockListItem
    const indexOfTargetLi = getPrevSiblingsNumber(doc, targetLi);
    // next li after target li
    const nextLi = doc.getNextSibling(targetLi.key) as ListItemBlock | null;

    const destinationDepthLevel = getListIndentLevel(doc, targetList) - 1;
    if (destinationDepthLevel < 0) {
      return;
    }

    // ancestor list on level less then current
    const destinationList = getAncestorListOfDepth(doc, block, destinationDepthLevel);
    if (!destinationList) {
      return;
    }

    ch.withoutNormalizing(() => {
      const change = ch;
      // if target li is not first in target list, split target list; disable normalization so that it doesn't normalize
      // two lists in one li
      if (indexOfTargetLi > 0) {
        ch.splitNodeByKey(targetList.key, indexOfTargetLi);
      }

      // move target li to the destination list,
      // use old versions of destinationList and targetLi because they weren't normalized or removed
      change.command(moveTargetLiToDestinationList, destinationList, targetLi);

      // use old versions of nextLi and targetLi because they weren't normalized or removed
      if (nextLi) {
        // separated part of target list or target list itself, it target li was the first li;
        // we are sure it exists because every li has a list
        const listToBecomeEmbedded = getClosestListAncestor(ch.value.document, nextLi) as Block;
        // make it child of target li
        change.command(makeEmbeddedListFromNextListItems, targetLi, listToBecomeEmbedded);
      }
    });
  });
};
