import FontSize from './FontSize';
import {ButtonType} from '../../interface';
import {buttonLabel, buttonTitle} from '../i18n';
import RenderFontSizeLarge from '../../../../plugins/renderers/FontSize/FontSizeLarge';

class FontSizeLarge extends FontSize {
  public type = ButtonType.FONT_SIZE_LARGE;
  public title = buttonTitle.FontSizeLarge;
  public label = buttonLabel.FontSizeLarge;
  public className = 'large';

  public plugins = [new RenderFontSizeLarge()];
}

export default FontSizeLarge;
