import React from 'react';
import {
  type RenderAnnotationProps,
  type RenderInlineProps,
  type Editor as ReactEditor
} from '@englex/slate-react';
import {type Editor, type Inline, type Next, type Value} from '@englex/slate';

import ToolbarButton from '../../ToolbarButton';
import type ControlledTooltip from '../../components/ControlledTooltip';
import LinkTooltip from './LinkTooltip';
import {ButtonType, type SchemaInlinePlugin, type SchemaInlineRules} from '../../interface';
import {
  type DataMap,
  getEditorId,
  type LinkInline,
  type LinkInlineData,
  SlateAnnotation,
  SlateInline
} from '../../../../interface';
import {buttonTitle, buttonTitleActive} from '../i18n';
import {
  addCreateLinkAnnotation,
  addLink,
  editLinkByKey,
  removeCreateLinkAnnotation,
  unlinkByKey
} from './changes';
import {isLinkInline} from '../../../../utils';

import './index.scss';

class Link extends ToolbarButton implements SchemaInlinePlugin {
  public icon = 'link';
  public iconActive = 'unlink';
  public title = buttonTitle.Link;
  public titleActive = buttonTitleActive.Link;
  public tooltip: ControlledTooltip;
  public type = ButtonType.LINK;
  public shortcut = 'mod+k';

  public inlineRules = (): SchemaInlineRules => ({
    type: SlateInline.LINK,
    rules: {
      text: /.+/,
      data: {
        href: (v: unknown) => typeof v === 'string'
      }
    }
  });

  protected toggleChange = (change: Editor) => {
    const {value} = change;

    if (!value.selection) return;

    const inline = value.document
      .getRootInlinesAtRange(value.selection)
      .find((i: Inline) => i.type === this.type);
    if (!inline && value.selection.isExpanded) {
      change.command(addCreateLinkAnnotation).blur();
    } else if (inline) {
      change.command(unlinkByKey, inline.key).focus();
    }
  };

  public isActive = (editor: Editor): boolean =>
    editor.readOnly ? false : this.selectionIsInLink(editor.value);

  public renderAnnotation = (
    props: RenderAnnotationProps,
    editor: ReactEditor & Editor,
    next: Next
  ) => {
    const {attributes, children, annotation, node} = props;
    if (editor.readOnly || annotation.type !== SlateAnnotation.CREATE_LINK) {
      return next();
    }

    const originAnnotation = editor.value.annotations.get(SlateAnnotation.CREATE_LINK);
    const shouldRenderTooltip = originAnnotation?.start?.key === node.key;

    return (
      <span onClick={e => e.stopPropagation()} className="slate-create-link">
        {shouldRenderTooltip ? (
          <LinkTooltip
            save={(data: LinkInlineData) => this.addLink(editor, data)}
            reset={(focus?: true) => this.removeAnnotation(editor, focus)}
            getTooltipContainer={() => document.querySelector('.slate-create-link') as HTMLElement}
            contentEditable={false}
          >
            <span {...attributes}>{children}</span>
          </LinkTooltip>
        ) : (
          <span {...attributes}>{children}</span>
        )}
      </span>
    );
  };

  public renderInline = (props: RenderInlineProps, editor: ReactEditor & Editor, next: Next) => {
    const {node, isSelected} = props;
    if (!isLinkInline(node)) {
      return next();
    }
    const data = node.data as DataMap<LinkInlineData>;
    if (editor.readOnly || !isSelected || !this.isActive(editor)) {
      return this.renderLink(props, data);
    }

    const href = data.get('href');

    return (
      <span onClick={e => e.stopPropagation()}>
        <LinkTooltip
          href={href}
          unlink={() => this.removeLink(editor, node)}
          save={(d: LinkInlineData) => this.editLinkNode(editor, node, d)}
          reset={(focus?: true) => this.moveSelectionAfterLink(editor, focus)}
          getTooltipContainer={this.getTooltipContainer(editor)}
        >
          {this.renderLink(props, data)}
        </LinkTooltip>
      </span>
    );
  };

  public isDisabled(editor: Editor) {
    const {blocks, fragment, selection} = editor.value;
    if (super.isDisabled(editor)) {
      return true;
    }
    if (!selection) {
      return false;
    }
    if (selection.isCollapsed) {
      return !this.isActive(editor);
    }
    if (!fragment.text.trim().length || blocks.size !== 1) {
      return true;
    }
    if (this.isActive(editor)) {
      return false;
    }
    return editor.value.document
      .getRootInlinesAtRange(selection)
      .some((i: Inline) => i.type === this.type);
  }

  protected getTooltipContainer = (editor: Editor): (() => HTMLElement) => {
    if (!this.editorId) {
      this.editorId = editor.query<string>(getEditorId);
    }
    return () => document.getElementById(this.editorId)!;
  };

  private renderLink = (
    {attributes, children}: RenderInlineProps,
    data: DataMap<LinkInlineData>
  ) => {
    return (
      <a {...attributes} href={data.get('href')} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  };

  private addLink = (editor: Editor, data: LinkInlineData) => {
    editor.command(addLink, data).focus();
  };

  private editLinkNode = (editor: Editor, node: LinkInline, data: LinkInlineData) => {
    editor.command(editLinkByKey, node.key, data).focus();
  };

  private removeLink = (editor: Editor, node: LinkInline) => {
    editor.command(unlinkByKey, node.key).focus();
  };

  private removeAnnotation = (editor: Editor, focus?: true) => {
    editor.command(removeCreateLinkAnnotation).command((c: Editor) => focus && c.focus());
  };

  private moveSelectionAfterLink = (editor: Editor, focus?: true) => {
    editor.moveToStartOfNextText().command((c: Editor) => focus && c.focus());
  };

  private selectionIsInLink = ({document, selection}: Value) => {
    if (!selection) return false;

    const links = document
      .getRootInlinesAtRange(selection)
      .filter((i: Inline) => i.type === this.type);
    const link = links.first();
    if (links.size === 1) {
      const nextSibling = document.getNextSibling(link.key);
      return (
        selection.start.isInNode(link) &&
        (selection.end.isInNode(link) ||
          (selection.end.offset === 0 && selection.end.isAtStartOfNode(nextSibling!)))
      );
    }
    return false;
  };

  protected onButtonMouseDown(e: React.MouseEvent<HTMLButtonElement>, editor: Editor) {
    super.onButtonMouseDown(e, editor);
    e.stopPropagation();
  }
}

export default Link;
