import TextAlign from './TextAlign';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import {JustifyContentValue, TextAlignValue} from '../../../../interface';

class TextAlignCenter extends TextAlign {
  public align = TextAlignValue.CENTER;
  public justify = JustifyContentValue.CENTER;
  public type = ButtonType.TEXT_ALIGN_CENTER;
  public title = buttonTitle.TextAlignCenter;
  public icon = 'align-center';
  public shortcut = 'mod+shift+alt?+e';
}

export default TextAlignCenter;
