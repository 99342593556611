import {type Block, type Editor, type Next} from '@englex/slate';
import {type Editor as ReactEditor} from '@englex/slate-react';
import type React from 'react';

import isShortcut from 'helpers/shortcut';

import {buttonTitle} from '../../i18n';
import {ButtonType} from '../../../interface';
import {outdent} from './changes';
import {getClosestListAncestor, getListIndentLevel} from './utils';
import {getListItemBlockOfBlock, getPreviousSiblingBlockInListItem} from '../utils';
import {type ListBlock, SlateBlock} from '../../../../../interface';
import {isBlockOfType} from '../../../../../utils';

import {Indentation} from './index';

export class Outdent extends Indentation {
  public icon = 'virc-outdent';
  public shortcut = ['mod+[', 'shift+tab'];
  public title = buttonTitle[ButtonType.OUTDENT];
  public type = ButtonType.OUTDENT;

  protected toggleChange = outdent;

  public onKeyDown = (event: React.KeyboardEvent, change: ReactEditor & Editor, next: Next) => {
    const handled = this.handleKeyDown(event, change);
    return !handled ? next() : undefined;
  };

  public handleKeyDown = (event: React.KeyboardEvent, change: ReactEditor & Editor) => {
    const {
      value: {document, startBlock}
    } = change;
    if (isShortcut(event, 'enter') && startBlock) {
      const parentBlock = document.getParent(startBlock.key);
      const isEmptyItem =
        parentBlock && isBlockOfType(parentBlock, SlateBlock.LIST_ITEM) && !parentBlock.text.length;
      if (isEmptyItem && !this.isDisabled(change)) {
        change.command(this.toggleChange);
        return true;
      }
    }

    if (!isShortcut(event, this.shortcut) || this.isDisabled(change)) {
      return;
    }

    event.preventDefault();
    change.command(this.toggleChange);
    return true;
  };

  public isDisabled(editor: Editor) {
    if (super.isDisabled(editor)) {
      return true;
    }

    const {blocks, document, startBlock} = editor.value;
    if (!startBlock) {
      return true;
    }

    const listItem = getListItemBlockOfBlock(document, startBlock);
    // if start block is in tail of list item, or not in list at all, the button is inactive
    if (!listItem || getPreviousSiblingBlockInListItem(document, startBlock)) {
      return true;
    }

    // assert as block here because if at list one block from selection is not in list, isDisabled of parent class returns true
    const currentList = getClosestListAncestor(document, startBlock) as ListBlock;
    const nextBlock = document.getNextBlock(startBlock.key);
    const nextBlockList = !!nextBlock && getClosestListAncestor(document, nextBlock);
    if (
      nextBlock &&
      nextBlockList &&
      getListIndentLevel(document, nextBlockList) > getListIndentLevel(document, currentList)
    ) {
      // if there is next list item and it's indent is bigger then current's
      return true;
    }

    const allBlocksAreMinLevel = blocks.every((block: Block) => {
      const list = getClosestListAncestor(document, block);
      return !!list && getListIndentLevel(document, list) <= 0;
    });

    return allBlocksAreMinLevel;
  }
}
