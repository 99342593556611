import {type DeserializeFn, type SerializeRules} from '@englex/slate-html-serializer';
import {type Editor, type GenericQuery, type Next} from '@englex/slate';

import StyledNode from '../StyledNode';
import {type HtmlSerializable, type NodeStyle} from '../../interface';
import {getElementCssStyleValue} from '../../utils';
import {getAlignmentBlock} from './utils';
import {
  getHtmlRules,
  type JustifyContentValue,
  SlateBlock,
  SlateObject,
  type StyledBlock,
  TextAlignValue
} from '../../../../interface';

abstract class TextAlign extends StyledNode<SlateBlock, StyledBlock> implements HtmlSerializable {
  public wrapper = 'div';
  public abstract align: TextAlignValue;
  public abstract justify: JustifyContentValue;

  protected renderStyleNodeTypes = [SlateBlock.IMAGE];

  public get nodeStyle(): NodeStyle<SlateBlock> {
    return {
      [SlateBlock.DEFAULT]: {
        textAlign: this.align,
        position: 'relative'
      },
      // TODO: this should be in image plugin
      [SlateBlock.IMAGE]: {
        justifyContent: this.justify
      },
      [SlateBlock.LIST_ITEM]: {
        justifyContent: this.justify
      }
    };
  }

  public onQuery = (query: GenericQuery<never>, editor: Editor, next: Next) => {
    if (query.type === getHtmlRules) {
      const rules: SerializeRules = next() || [];
      return this.htmlRules().concat(rules);
    }
    return this.onToolbarButtonQuery(query, editor, next);
  };

  public fromHtml: DeserializeFn = (el: HTMLElement, next) => {
    if (el.tagName.toLowerCase() !== 'p') {
      return;
    }

    let textAlign = el.style.textAlign; // for google docs

    if (!textAlign && !textAlign!.length && /^p\d+$/.test(el.className)) {
      // for RTF and Pages on macOS
      const selector = `${el.tagName.toLowerCase()}.${el.className}`;
      const propertyName = import.meta.env.MODE === 'test' ? 'text-align' : 'textAlign';
      const align = getElementCssStyleValue(el, selector, propertyName);
      if (align) {
        textAlign = align;
      }
    }

    if ((textAlign && textAlign === TextAlignValue.LEFT) || textAlign === '') {
      return {
        object: SlateObject.BLOCK,
        type: SlateBlock.DEFAULT,
        nodes: next(el.childNodes)
      };
    }

    if (textAlign && this.align === textAlign) {
      return {
        object: SlateObject.BLOCK,
        type: SlateBlock.DEFAULT,
        nodes: next(el.childNodes),
        data: {
          style: this.getStyle(SlateBlock.DEFAULT)
        }
      };
    }

    return;
  };

  public htmlRules = (): SerializeRules => {
    return [
      {
        deserialize: this.fromHtml
      }
    ];
  };

  public isActive = (editor: Editor) => {
    const block = getAlignmentBlock(editor.value);
    if (!block) {
      return this.align === TextAlignValue.LEFT;
    } else {
      const style = block.data.get('style');

      // TODO: think about better solution to determine alignment style
      return !!style && (this.align === style.textAlign || this.justify === style.justifyContent);
    }
  };
}

export default TextAlign;
