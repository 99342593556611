import {type Editor} from '@englex/slate';

import {type LinkInlineData, SlateAnnotation, SlateInline} from '../../../../interface';

export const removeCreateLinkAnnotation = (change: Editor) => {
  const {value} = change;
  if (value.annotations.size) {
    const annotation = value.annotations.get(SlateAnnotation.CREATE_LINK);

    if (annotation) {
      change.withoutSaving(() => {
        change.removeAnnotation(annotation);
      });
    }
  }
};

export const addLink = (change: Editor, data: LinkInlineData) =>
  change
    .command(removeCreateLinkAnnotation)
    .wrapInline({
      type: SlateInline.LINK,
      data: {href: data.href}
    })
    .moveToEnd();

export const unlinkByKey = (change: Editor, key: string) => {
  change.unwrapInlineByKey(key, SlateInline.LINK);
};

export const addCreateLinkAnnotation = (change: Editor) => {
  const {selection, document} = change.value;

  if (!selection) return;

  const {start, end} = selection;
  const linkAnnotation = document.createAnnotation({
    key: SlateAnnotation.CREATE_LINK,
    anchor: start,
    focus: end,
    type: SlateAnnotation.CREATE_LINK
  });

  change.withoutSaving(() => {
    change.addAnnotation(linkAnnotation);
  });
};

export const editLinkByKey = (change: Editor, key: string, data: LinkInlineData) => {
  return change
    .setNodeByKey(key, {
      data: {href: data.href}
    })
    .moveToStartOfNextText();
};
