import type React from 'react';
import {type Block, type Editor, type Inline} from '@englex/slate';
import {type List} from 'immutable';

import ToolbarButton from '../../ToolbarButton';
import {SlateBlock, type SlateInline} from '../../../../interface';
import {type NodeStyle} from '../../interface';

abstract class StyledNode<
  T extends SlateInline | SlateBlock,
  N extends Inline | Block
> extends ToolbarButton {
  public abstract wrapper: React.ElementType | string;
  public abstract get nodeStyle(): NodeStyle<T>;
  protected abstract renderStyleNodeTypes: T[];
  protected removeStyle = false;
  protected defaultRenderNodeTypes: T[] = [SlateBlock.DEFAULT] as T[];
  protected toggleChange = (change: Editor) => {
    const {value} = change;
    const {blocks} = value;

    (blocks as List<N>)
      .filter((n: N) =>
        [...this.defaultRenderNodeTypes, ...this.renderStyleNodeTypes].includes(n.type as T)
      )
      .forEach((n: N) => {
        const {data} = n;
        const style = data.get('style');
        if (style) {
          const nodeStyle = this.getStyle(n.type as T);
          const styleProps = Object.keys(nodeStyle).map(cssProp => {
            return style[cssProp] ? cssProp : false;
          });
          const entityHasStyle = styleProps.every(p => !!p && style[p] === nodeStyle[p]);
          const entityHasPartialStyle = styleProps.some(p => !!p && style[p] === nodeStyle[p]);
          if (!entityHasStyle && entityHasPartialStyle && !this.removeStyle) {
            // eslint-disable-next-line no-console
            console.warn(
              `StyledNode '${n.type}' tries to set style, which can override some already existing properties. Current style:`,
              style,
              'styles to apply:',
              nodeStyle
            );
          }
          if (this.removeStyle) {
            const newStyle = Object.keys(style)
              .filter(k => nodeStyle[k])
              .reduce((s: React.CSSProperties, key: keyof React.CSSProperties) => {
                const {[key]: val, ...st} = s;
                return st;
              }, style);
            if (Object.keys(newStyle).length === 0) {
              change.setNodeByKey(n.key, {
                type: n.type,
                data: data.delete('style')
              });
            } else {
              change.setNodeByKey(n.key, {
                type: n.type,
                data: data.set('style', newStyle)
              });
            }
          } else if (!entityHasStyle) {
            change.setNodeByKey(n.key, {
              type: n.type,
              data: data.set('style', {
                ...style,
                ...nodeStyle
              })
            });
          }
        } else {
          if (!this.removeStyle) {
            change.setNodeByKey(n.key, {
              type: n.type,
              data: data.set('style', this.getStyle(n.type as T))
            });
          }
        }
      });
  };

  protected getStyle = (nodeType: T): React.CSSProperties =>
    this.nodeStyle[nodeType] ? this.nodeStyle[nodeType]! : {};
}

export default StyledNode;
