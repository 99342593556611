import {type Plugin} from '@englex/slate-react';
import {type Editor} from '@englex/slate';

import {buttonTitle} from '../i18n';
import {ButtonType} from '../../interface';
import NestedToolbar from '../NestedToolbar';
import type TextAlign from './TextAlign';
import TextAlignJustify from './TextAlignJustify';
import TextAlignLeft from './TextAlignLeft';
import TextAlignCenter from './TextAlignCenter';
import TextAlignRight from './TextAlignRight';
import RenderTextAlignment from '../../../../plugins/renderers/TextAlign/TextAlignment';
import {getAlignmentBlock} from './utils';

type AlignClasses<T extends typeof TextAlign = typeof TextAlign> = T[];

interface Options {
  alignClasses?: AlignClasses;
}

const defaultClasses: AlignClasses = [
  TextAlignLeft,
  TextAlignCenter,
  TextAlignRight,
  TextAlignJustify
];

class TextAlignment extends NestedToolbar {
  public plugins: Plugin[];

  public icon = 'align-left';
  public title = buttonTitle.TextAlignment;
  public type = ButtonType.TEXT_ALIGNMENT;

  public constructor({alignClasses = defaultClasses}: Options = {}) {
    super();

    const plugins: Plugin[] = alignClasses.map(p => {
      const PluginClass = p as typeof TextAlignLeft;
      return new PluginClass({toolbar: this});
    });

    this.plugins = plugins.concat([new RenderTextAlignment()]);
  }

  public getIcon = (editor: Editor) => {
    const block = getAlignmentBlock(editor.value);
    if (block) {
      const style = block.data.get('style');
      const align = style ? style.textAlign : undefined;
      const justify = style ? style.justifyContent : undefined;

      if (!align && !justify) {
        return this.icon;
      }
      const plugin = this.plugins.find(
        (textAlign: TextAlign) => align === textAlign.align || justify === textAlign.justify
      ) as TextAlign;
      return plugin ? plugin.getIcon(editor) : this.icon;
    }
    return this.icon;
  };
}

export default TextAlignment;
