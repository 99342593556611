import {type Block, type Editor} from '@englex/slate';

import {buttonTitle} from '../../i18n';
import {ButtonType} from '../../../interface';
import {indent} from './changes';
import {getClosestListAncestor, getListIndentLevel} from './utils';
import {getListItemBlockOfBlock, getPreviousLi, getPreviousSiblingBlockInListItem} from '../utils';

import {Indentation} from './index';

export class Indent extends Indentation {
  public icon = 'virc-indent';
  public shortcut = ['mod+]', 'tab'];
  public title = buttonTitle[ButtonType.INDENT];
  public type = ButtonType.INDENT;

  protected toggleChange = indent;

  public isDisabled(editor: Editor) {
    if (super.isDisabled(editor)) {
      return true;
    }
    const {blocks, document, startBlock} = editor.value;
    if (!startBlock) {
      return true;
    }

    const listItem = getListItemBlockOfBlock(document, startBlock);
    if (
      !listItem ||
      !getPreviousLi(document, listItem) ||
      getPreviousSiblingBlockInListItem(document, startBlock)
    ) {
      // if there is no previous list item or block is not first in list item
      return true;
    }

    const allBlocksAreMaxLevel = blocks.every((block: Block) => {
      const list = getClosestListAncestor(document, block);
      return !!list && getListIndentLevel(document, list) >= Indent.maxLevel;
    });

    return allBlocksAreMaxLevel;
  }
}
