import FontSize from './FontSize';
import {ButtonType} from '../../interface';
import {buttonLabel, buttonTitle} from '../i18n';
import RenderFontSizeSmallest from '../../../../plugins/renderers/FontSize/FontSizeSmallest';

class FontSizeSmallest extends FontSize {
  public type = ButtonType.FONT_SIZE_SMALLEST;
  public title = buttonTitle.FontSizeSmallest;
  public label = buttonLabel.FontSizeSmallest;
  public className = 'smallest';

  public plugins = [new RenderFontSizeSmallest()];
}

export default FontSizeSmallest;
