import TextAlign from './TextAlign';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import {JustifyContentValue, TextAlignValue} from '../../../../interface';

class TextAlignRight extends TextAlign {
  public align = TextAlignValue.RIGHT;
  public justify = JustifyContentValue.RIGHT;
  public type = ButtonType.TEXT_ALIGN_RIGHT;
  public title = buttonTitle.TextAlignRight;
  public icon = 'align-right';
  public shortcut = 'mod+shift+r';
}

export default TextAlignRight;
