import TextAlign from './TextAlign';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import {JustifyContentValue, TextAlignValue} from '../../../../interface';

class TextAlignLeft extends TextAlign {
  public align = TextAlignValue.LEFT;
  public justify = JustifyContentValue.LEFT;
  public type = ButtonType.TEXT_ALIGN_LEFT;
  public title = buttonTitle.TextAlignLeft;
  public icon = 'align-left';
  public shortcut = 'mod+shift+l';
  protected removeStyle = true;
}

export default TextAlignLeft;
