import {type Block, type Value} from '@englex/slate';

import {type StyledBlock} from '../../../../interface';

// TODO: think about better solution to determine alignment style
export const getAlignmentBlock = (value: Value): StyledBlock =>
  value.blocks
    .filter(
      (block: Block) =>
        (block.data.has('style') && block.data.get('style').textAlign) ||
        (block.data.has('style') && block.data.get('style').justifyContent)
    )
    .first();
